<template>
  <div>
    <!-- DESKTOP -->
    <div class="mb-xl-report-bonus-sign pl-8 pr-8">
      <v-row class="mt-11">
        <v-col class="text-left" cols="6">
          <p class="txt-title-report-bonus">Documentos</p>
        </v-col>
        <v-col cols="3">
          <p>
            <v-btn
              class="mt-4"
              @click="download()"
              block
              style="background-color: #fff"
              rounded
              outlined
              color="#466be3"
            >
              <strong> Descargar lista </strong>
              <!-- Icono -->
            </v-btn>
          </p>
        </v-col>
        <v-col cols="3">
          <p>
            <v-btn
              class="mt-4"
              @click="openDialog = true"
              block
              style="background-color: #fff"
              rounded
              outlined
              color="#466be3"
            >
              <strong> Ajuste Masivo </strong>
            </v-btn>
          </p>
        </v-col>
      </v-row>
      <!-- Filtros -->
      <v-row>
        <v-col cols="6">
          <v-autocomplete
            prepend-inner-icon="mdi-filter-outline"
            v-model="form.document_number"
            :items="listDocuments"
            label="Seleccione el documento"
            item-text="nombre"
            item-value="ds_id"
            @change="handleDocument"
          ></v-autocomplete>
          <!-- :disabled="listDocuments.length === 0" -->
        </v-col>
      </v-row>
      <!-- Tabla -->
      <v-row>
        <v-col cols="12">
          <v-data-table
            :loading="loading.table"
            :items-per-page="10"
            :headers="headers"
            :items="items"
            item-key="name"
            class="elevation-1 table-report-header report-document__table"
            :footer-props="{ itemsPerPageOptions: [10, 15, 30] }"
          >
            <!-- Sin resultados de busqueda -->
            <template v-slot:no-results>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontraron resultados</b>
              </v-alert>
            </template>
            <!-- Sin Data -->
            <template v-slot:no-data>
              <v-alert
                icon="mdi-information-outline"
                prominent
                text
                type="info"
              >
                <b>No se encontró información.</b>
              </v-alert>
            </template>
            <!-- Loading -->
            <template v-slot:progress>
              <v-progress-linear
                color="#214484"
                :height="5"
                indeterminate
                stream
              ></v-progress-linear>
            </template>
            <!-- Nombre -->
            <template v-slot:item.Colaborador="{ item }">
              <div class="float-left mt-2 pr-4">
                <img
                  style="border-radius: 120px"
                  width="35"
                  height="35"
                  :src="item.Img_colaborador"
                />
              </div>
              <div>
                <p class="mb-0 mt-2">{{ capitalizeWords(item.Colaborador) }}</p>
              </div>
            </template>

            <!-- Creador -->

            <template v-slot:item.Crea_documento="{ item }">
              <p class="mb-0 mb-2">
                {{ capitalizeWords(item.Crea_documento) }}
              </p>
            </template>

            <!-- fecha creacion -->

            <template v-slot:item.dsu_created_at="{ item }">
              <p class="mb-0 mb-2">{{ item.dsu_created_at | formatTime }}</p>
            </template>

            <!-- fecha Actualización -->
            <template v-slot:item.fecha_entrega="{ item }">
              <p class="mb-0 mb-2">{{ item.fecha_entrega | formatTime }}</p>
            </template>

            <!-- Acciones -->
            <template v-slot:item.actions="{ item }">
              <v-tooltip max-width="300" color="#000" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="openModal(item)"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    color="blue"
                    fab
                  >
                    <v-icon> mdi-calendar </v-icon>
                  </v-btn>
                </template>
                <span> Actualizar fecha entrega</span>
              </v-tooltip>

              <v-tooltip max-width="300" color="#000" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    @click="seeDocument(item)"
                    v-bind="attrs"
                    v-on="on"
                    icon
                    fab
                    color="blue"
                  >
                    <v-icon> mdi-eye-outline </v-icon>
                  </v-btn>
                </template>
                <span> Visualización del documento</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="openCalendarDialog"
      width="850"
      persistent
      @input="onDialogOpen"
    >
      <v-card style="border-radius: 15px">
        <v-card-title class="pt-6">
          <v-layout justify-center>
            <v-card-title class="headline"> Seleccione una fecha </v-card-title>
          </v-layout>
          <v-btn
            @click="openCalendarDialog = false"
            color="#466BE3"
            icon
            x-small
            style="display: flex; left: 18px; bottom: 45px"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-toolbar-title v-if="calendarTitle" style="color: #000">
            {{ calendarTitle }}
          </v-toolbar-title>
          <v-calendar
            ref="calendar"
            v-model="selectedDate"
            min="2023-01-01"
            max="2024-12-31"
            color="blue"
            @change="updateTitle"
          ></v-calendar>
        </v-card-text>
        <v-card-actions class="pb-8">
          <v-row>
            <v-col cols="6">
              <v-btn
                block
                color="#466BE3"
                dark
                rounded
                @click="openCalendarDialog = false"
                outlined
              >
                CERRAR
              </v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block color="#466BE3" dark rounded @click="confirmDate">
                Confirmar
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="openDialog" max-width="800px">
      <v-card>
        <v-progress-circular
          v-if="isLoading"
          :size="50"
          :width="5"
          indeterminate
          color="primary"
          style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            z-index: 10;
          "
        ></v-progress-circular>
        <!-- Título del diálogo -->
        <v-card-title class="headline"> Proceso de Documentación </v-card-title>

        <!-- Stepper Horizontal -->
        <v-stepper v-model="currentStep" class="v-stepper--horizontal">
          <!-- Stepper Header -->
          <v-stepper-header>
            <v-stepper-step :complete="currentStep > 1" step="1">
              Selección
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 2" step="2">
              Subida de Documento
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 3" step="3">
              Selección de Fecha
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 4" step="4">
              Validación
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step :complete="currentStep > 5" step="5">
              Resultados
            </v-stepper-step>
          </v-stepper-header>

          <!-- Paso 1: Selección de Opción -->
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card-header>
                <p class="document-managmet___txt-dialog-title">
                  Selecciona el documento
                </p>
              </v-card-header>
              <v-card-text>
                <div class="d-flex justify-space-around">
                  <v-autocomplete
                    prepend-inner-icon="mdi-filter-outline"
                    v-model="form.idDoc"
                    :items="listDocuments"
                    label="Seleccione el documento"
                    item-text="nombre"
                    item-value="ds_id"
                  ></v-autocomplete>
                </div>
              </v-card-text>

              <v-card-actions>
                <v-btn
                  :disabled="!form.idDoc"
                  color="primary"
                  @click="nextStep"
                >
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <!-- Paso 2: Subida de Documento -->
            <v-stepper-content step="2">
              <v-card-header>
                <p class="document-managmet___txt-dialog-title">
                  Adjuntar archivo de importación
                </p>
              </v-card-header>
              <v-card-text class="py-5">
                <p class="pb-0 mb-2 text-left">
                  El archivo debe contar con lo siguiente:
                </p>
                <p class="pb-0 mb-2 text-left">
                  1. una columna para la cédula, esta puede tener como título la
                  siguiente variable:
                  <strong> cedulas.</strong>.
                </p>
                <p class="pb-0 mb-2 text-left">
                  2.Recuerde que en el punto anterior la variable no deben tener
                  tildes, ni caracteres especiales.
                </p>
                <p class="pb-0 mb-2 text-left">
                  3. Debe ser un archivo tipo excel (xlsx) que no supere 1MB de
                  peso.
                </p>
                <v-row class="mt-5">
                  <v-col cols="9">
                    A continuación encontrarás el formato para la actualización
                    de múltiples fechas. Recuerda que puedes descargar y
                    trabajar sobre este archivo.
                  </v-col>
                  <v-col cols="3">
                    <v-btn
                      color="success"
                      class="mr-4"
                      @click="downloadFormat()"
                      :loading="loading4"
                      :disabled="loading4"
                      block
                    >
                      Descargar
                    </v-btn>
                  </v-col>
                </v-row>
                <v-file-input
                  class="mt-8"
                  v-model="file"
                  color="#466BE3"
                  counter
                  label="Adjuntar archivo"
                  prepend-icon="mdi-paperclip"
                  accept=".xlsx"
                  outlined
                  :show-size="1000"
                >
                  <template v-slot:selection="{ text }">
                    <v-chip color="#466BE3" dark label small>
                      {{ text }}
                    </v-chip>
                  </template>
                </v-file-input>
              </v-card-text>
              <v-card-actions>
                <v-btn color="primary" @click="prevStep"> Atrás </v-btn>
                <v-btn color="primary" :disabled="!file" @click="nextStep">
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <!-- Paso 3: Selección de Fecha -->
            <v-stepper-content step="3">
              <v-card-text>
                <p>Seleccione una fecha para el proceso:</p>
                <v-toolbar-title v-if="calendarTitle" style="color: #000">
                  {{ calendarTitle }}
                </v-toolbar-title>
                <v-calendar
                  ref="calendar"
                  v-model="selectedDate"
                  color="primary"
                  min="2023-01-01"
                  max="2024-12-31"
                  @change="updateTitle"
                ></v-calendar>
              </v-card-text>

              <v-card-actions>
                <v-btn color="primary" @click="prevStep"> Atrás </v-btn>
                <v-btn
                  color="primary"
                  :disabled="!selectedDate"
                  @click="nextStep"
                >
                  Continuar
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
            <!-- Paso 3: Selección de Fecha -->

            <v-stepper-content step="4">
              <v-card-text>
                <p class="document-managmet___txt-dialog-title">Validación</p>
                <p class="pb-0 mb-2 text-left">
                  <strong>Nombre del documento:</strong>
                  {{ selectedDocumentName }}
                </p>
                <p class="pb-0 mb-2 text-left">
                  <strong>Fecha seleccionada:</strong> {{ selectedDate }}
                </p>
                <p class="pb-0 mb-2 text-left" v-if="file && file.name">
                  <strong>Archivo a ser subido:</strong> {{ file.name }}
                </p>
              </v-card-text>

              <v-card-actions>
                <v-btn color="primary" @click="prevStep"> Atrás </v-btn>
                <v-btn color="success" @click="confirmAndProceed">
                  Confirmar
                </v-btn>
              </v-card-actions>
            </v-stepper-content>

            <v-stepper-content step="5">
              <v-card-text>
                <p class="document-managmet___txt-dialog-title">
                  Resultados del Proceso
                </p>
                <v-divider></v-divider>

                <v-row class="pt-3">
                  <v-col cols="4">
                    <strong>Número de registros actualizados:</strong>
                  </v-col>
                  <v-col cols="8">
                    <v-chip color="success" dark label small>
                      {{ resultData.actualizados }}
                    </v-chip>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <strong>Errores:</strong>
                  </v-col>
                  <v-col cols="8">
                    <ul>
                      <li
                        v-for="(error, cedula) in resultData.errores"
                        :key="cedula"
                      >
                        <v-chip color="red" dark label small>
                          {{ cedula + 1 }} : {{error}}
                        </v-chip>
                      </li>
                    </ul>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col cols="4">
                    <strong>Número de registros no actualizados:</strong>
                  </v-col>
                  <v-col cols="8">
                    <v-chip color="error" dark label small>
                      {{ resultData.no_actualizados }}
                    </v-chip>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-card-actions>
                <v-btn color="primary" @click="prevStep"> Atrás </v-btn>
                <v-btn color="success" @click="finishProcess">
                  Finalizar
                </v-btn>
              </v-card-actions>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card>
    </v-dialog>
    <Alert
      :open="message.dialog"
      :text="message.sms"
      :title="message.title"
      :type="message.type"
      @close="message.dialog = false"
    >
    </Alert>

    <v-overlay :value="loadingAll">
      <v-progress-circular :size="120" color="#466BE3" indeterminate>
        Cargando...
      </v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Api from "@/utils/api";
import Alert from "../../components/Alert.vue";
import color from "@/mixins/color";
// import { read, utils } from "xlsx";
export default {
  components: {
    Alert,
  },
  mixins: [color],
  name: "upload",
  filters: {
    formatTime(value) {
      if (value) {
        // Split the date and time based on the space separator
        let dateTimeParts = value.split("T");
        if (dateTimeParts.length >= 2) {
          // Extract only the date part
          let date = dateTimeParts[0];
          return date;
        }
      }
      return value;
    },
  },
  data() {
    return {
      openConfirm: false,
      openCalendarDialog: false, // Añadido para manejar el estado del diálogo de calendario
      selectedDate: new Date().toISOString().substr(0, 10),
      message: {
        dialog: false,
        title: "",
        sms: "",
        type: "",
      },
      loadingAll: false,
      listDocuments: [],
      listUsers: [],
      listStatus: [
        {
          name: "Firmado",
          id: 1,
        },
        {
          name: "Sin firma",
          id: 2,
        },
      ],
      loading: {
        table: false,
      },
      form: {
        document_number: null,
        idDoc: "",
        file: null,
      },
      items: [],
      menu2: false,
      headers: [
        {
          text: "Usuario",
          align: "start",
          value: "Colaborador",
          class: "report-document__header",
        },
        {
          text: "Cedula",
          align: "start",
          value: "Doc_colaborador",
          class: "report-document__header",
        },
        {
          text: "Nombre Formulario",
          value: "nombre",
          class: "report-document__header",
        },
        {
          text: "Creado por",
          value: "Crea_documento",
          class: "report-document__header",
        },
        {
          text: "Fecha Creación",
          value: "dsu_created_at",
          class: "report-document__header",
        },
        {
          text: "Fecha Entrega",
          value: "fecha_entrega",
          class: "report-document__header",
        },
        {
          text: "Acciones",
          align: "left",
          value: "actions",
          class: "report-document__header",
        },
      ],
      token: "",
      name: "",
      infoToken: {
        document: "",
      },
      openDialog: false,
      currentStep: 1,
      menuOptions: ["Opción 1", "Opción 2", "Opción 3"],
      selectedOption: null,
      uploadedFile: null,
      selectedDate: null,
      file: null,
      resultData: [],
      isLoading: false,
      calendarTitle: "",
    };
  },
  watch: {
    "form.date": function (n) {
      if (n && n.length > 1) {
        // this.getDocuments();
        this.dinamicFilter();
      }
    },
    "form.name": function (n) {
      this.dinamicFilter();
    },
    "form.document_number": function (n) {
      this.dinamicFilter();
    },
    "form.is_sign": function (n) {
      this.dinamicFilter();
    },
  },
  methods: {
    getToken() {
      var token = localStorage.getItem("token");
      var tokenb64 = localStorage.getItem("tokenB64");
      this.token = tokenb64;
      var div = token.split(".");
      if (div.length >= 2) {
        this.infoToken = JSON.parse(atob(div[1]));
        var nameList = this.infoToken.fullName.split(" ");

        if (nameList.length > 1) {
          if (nameList[0] != "") {
            this.name = nameList[0];
          } else {
            this.name = nameList[1];
          }
        } else {
          this.name = "";
        }
      } else {
        this.infoToken = {
          document: "",
        };
      }
    },
    capitalizeWords(name) {
      return name.replace(/\b\w/g, (char) => char.toUpperCase());
    },
    handleDocument() {
      this.getAllUsersPbot();
    },
    getDocumentPbot() {
      this.loadingAll = true;
      var data = new FormData();
      data.append("pbot", true);
      Api.DocumentsPbot()
        .getDocumentList(this.token, data)
        .then((resp) => resp.json())
        .then((res) => {
          this.listDocuments = res.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    getAllUsersPbot() {
      this.loadingAll = true;
      var data = new FormData();
      data.append("pbot", true);
      data.append("ds_id", this.form.document_number);
      data.append("is_sign", true);
      Api.DocumentsPbot()
        .getUsersList(this.token, data)
        .then((resp) => resp.json())
        .then((res) => {
          this.items = res.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    download() {
      this.loadingAll = true;
      const data = new FormData();
      data.append("pbot", true);
      data.append("ds_id", this.form.document_number);
      data.append("is_sign", true);
      Api.DocumentsPbot()
        .downloadList(this.token, data)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download =
            "Documentos" + new Date().toISOString().substr(0, 10) + ".xlsx"; // Nombre del archivo descargado
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error al descargar el archivo:", error);
          // Mostrar mensaje de error si es necesario
          this.message.dialog = true;
          this.message.sms = "Hubo un problema al descargar el archivo.";
          this.message.title = "Error";
          this.message.type = "error";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    seeDocument(item) {
      this.loadingAll = true;
      Api.DocumentsPbot()
        .seeDocument(this.token, item.dsu_id)
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res);
          window.open(res.data.sign, "_blank");
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    confirmDate() {
      if (this.selectedDate && this.selectedItem) {
        this.openCalendarDialog = false;
      }
      const fecha = new Date(this.selectedDate);
      const fechaISO = fecha.toISOString().split("T")[0]; // "2024-08-26"
      var data = {
        dsu_id: this.selectedItem.dsu_id,
        fecha_entrega: fechaISO,
      };
      Api.DocumentsPbot()
        .updateDate(this.token, data)
        .then((resp) => resp.json())
        .then((res) => {
          console.log(res);
          this.message.dialog = true;
          this.message.title = "Excelente";
          this.message.sms = "Se actualizó correctamente la fecha.";
          this.message.type = "success";
          this.getAllUsersPbot();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    downloadFormat() {
      this.loadingAll = true;
      Api.DocumentsPbot()
        .downloadPlantilla(this.token)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.download = "plantilla.xlsx"; // Nombre del archivo descargado
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch((error) => {
          console.error("Error al descargar el archivo:", error);
          // Mostrar mensaje de error si es necesario
          this.message.dialog = true;
          this.message.sms = "Hubo un problema al descargar el archivo.";
          this.message.title = "Error";
          this.message.type = "error";
        })
        .finally(() => {
          this.loadingAll = false;
        });
    },
    nextStep() {
      this.currentStep += 1;
      if (this.currentStep === 4) {
        this.selectedDocumentName =
          this.listDocuments.find((doc) => doc.ds_id === this.form.idDoc)
            ?.nombre || "";
      }
    },
    prevStep() {
      this.currentStep -= 1;
    },
    confirmAndProceed() {
      this.isLoading = true;
      const fecha = new Date(this.selectedDate);
      const fechaISO = fecha.toISOString().split("T")[0]; // "2024-08-26"
      var data = new FormData();
      data.append("doc_id", this.form.idDoc);
      data.append("fecha_entrega", fechaISO);
      data.append("cedulas", this.file);
      Api.DocumentsPbot()
        .updateMultiple(this.token, data)
        .then((resp) => resp.json())
        .then((res) => {
          this.resultData = res;
          this.nextStep();
          this.getDocumentPbot();
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    finishProcess() {
      this.openDialog = false;
      this.currentStep = 1;
      this.form.idDoc = null;
      this.file = null;
      this.fileName = "";
      this.selectedDate = null;
      this.selectedDocumentName = "";
      this.resultData = {};
    },
    openModal(data) {
      // this.openConfirm = true;
      this.selectedItem = data; // Almacena el elemento seleccionado
      this.openCalendarDialog = true;
      this.docId = data.id;
    },
    onDialogOpen() {
      // Verifica si el dialogo está abierto
      if (this.openCalendarDialog) {
        this.$nextTick(() => {
          this.updateTitle();
        });
      }
    },
    updateTitle() {
      // Verifica si la referencia al calendario está disponible y actualiza el título
      console.log(this.$refs);
      if (this.$refs.calendar) {
        this.calendarTitle = this.$refs.calendar.title;
      }
    },
  },
  created() {
    this.getToken();
    this.getDocumentPbot();
    this.getAllUsersPbot();
  },
  mounted() {
    this.getToken();
    this.$refs.calendar.checkChange();
  },
  computed: {
    calendarTitle() {
      return this.$refs.calendar ? this.$refs.calendar.title : "";
    },
  },
};
</script>
<style lang="less">
@import "./../../assets/css/main.less";

.black {
  color: #000000;
}
.document-managmet___txt-dialog-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #353535;
}

.v-stepper--horizontal {
  justify-content: space-between;
}

.v-card-title {
  background-color: #f5f5f5;
}

.v-card-actions {
  display: flex;
  justify-content: space-between;
}

.v-stepper__step--complete .v-stepper__step__step {
  background-color: #4caf50 !important;
  color: white !important;
}

.mb-xl-report-bonus-sign {
  display: block;
}

.mb-sm-report-bonus-sign {
  display: none;
}

.txt-title-report-bonus {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 700;
  font-size: 50px;
  line-height: 59px;
  color: #353535;
}

.text-apps-blue-sm {
  font-weight: 300;
  font-size: 25px;
  line-height: 30px;
  color: #466be3;
}

.text-apps-sm {
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #626262;
  font-family: "AsapRegular";
}

.document-managmet1__txt-dialog-title {
  font-family: "RobotoRegular";
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #353535;
}

.document-managmet1__txt-dialog-sms {
  font-family: "RobotoMedium";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  color: #898989;
}

.report-document__header {
  color: #353535 !important;
}

tr,
th,
td,
span .report-document__table .text-start .sortable {
  font-size: 15px !important;
  font-family: "RobotoRegular" !important;
}

.report-document__table .v-data-table-header {
  font-family: "RobotoRegular";
  background-color: #f2f4ff;
  font-style: normal;
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 19px;
  color: #353535 !important;
}

/* LAPTOP 4k */

@media (max-width: 1900px) {
}

/* LAPTOP LARGE */

@media (max-width: 1600px) {
}

/* LAPTOP LARGE */

@media (max-width: 1400px) {
}

/* LAPTOP NORMAL */

@media (max-width: 1200px) {
}

/* TABLET */

@media (max-width: 1023px) {
  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-report-bonus-sign {
    display: none;
  }

  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE L*/
@media (max-width: 767px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }

  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-report-bonus-sign {
    display: none;
  }

  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE M */
@media (max-width: 375px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }

  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-report-bonus-sign {
    display: none;
  }

  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}

/* MOBILE S */
@media (max-width: 320px) {
  #app2 {
    background: #ffffff;
    border: 1px dashed #466be3;
    border-radius: 15px;
    height: 253px;
  }

  .txt-upload-commision {
    font-size: 18px;
    font-family: "RobotoRegular";
    text-align: left;
  }

  .mb-xl-report-bonus-sign {
    display: none;
  }

  .mb-sm-report-bonus-sign {
    display: block;
    text-align: initial;
  }
}
</style>
